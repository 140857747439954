import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input, Row, Col, Spin } from "antd";
import report from "./client/style";
import { Typography } from "antd";
import COLORS from "../../Common/Colors";
import styled from "styled-components";
import StyledClientWrapper from "./style";
import axios from "axios";
import Details from "./Details/DetailsData";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditClientData,
  setGetAllClientData,
} from "../../Redux/systemSlice";
import filter from "../../../src/assets/Images/filter.png";

const { Title } = Typography;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

function Client() {
  useEffect(() => {
    handleGetClientData();
  }, []);
  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showTableIcons, setShowTableIcon] = useState(true);
  const [showTableHover, setShowTableHover] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectDeleteData, setSelectDeleteData] = useState();
  const [inputChange, setInputChange] = useState("");
  const [getFilteredClient, setFilteredClient] = useState(getAllClientData);
  //   {
  //     key: "1",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  //   {
  //     key: "2",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  //   {
  //     key: "2",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  //   {
  //     key: "4",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  //   {
  //     key: "5",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  //   {
  //     key: "6",
  //     clientName: "William Sebastin",
  //     clientId: "bmw1234",
  //     emailId: "William Sebastin@gmail.com",
  //     companyName: "Tale Academy ",
  //     employee: "78",
  //     subscription: "Basic",
  //   },
  // ];

  // const handleDelete = async (data, index) => {
  //   setSelectDeleteData(data);
  //   setDeleteModalOpen(true);
  // };

  // const handleCloseDelete = () => {
  //   setDeleteModalOpen(false);
  // };

  // const handleConfirmDelete = async () => {
  //   setLoading(true);
  //   const url =
  //     "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/deleteData";
  //   console.log("Deleting client with ID:", selectDeleteData);
  //   try {
  //     const response = await axios.delete(`${url}/${selectDeleteData.id}`);
  //     if (
  //       response.data.status === 200 ||
  //       response.data.status === 201 ||
  //       response.data.statusCode === 200 ||
  //       response.data.statusCode === 201
  //     ) {
  //       setLoading(false);
  //       const findIndex = getAllClientData.findIndex(
  //         (item) => item.id === selectDeleteData.id
  //       );
  //       if (findIndex !== -1) {
  //         const UpdatedClientData = [...getAllClientData];
  //         UpdatedClientData.splice(findIndex, 1);
  //         dispatch(setGetAllClientData(UpdatedClientData));
  //       }
  //       setDeleteModalOpen(false);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error.response?.data || error.message);
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    const filteredData = getAllClientData?.filter((item) => {
      if (inputChange !== "") {
        return item.company_name
          .toLowerCase()
          ?.includes(inputChange?.toLowerCase());
      } else {
        return item;
      }
    });
    setFilteredClient(filteredData);
  }, [inputChange, getAllClientData]);

  const handleInput = (e) => {
    setInputChange(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`; // Format: DD-MM-YYYY
  };

  const handleGetClientData = async () => {
    setLoading(true);
    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/fetchdata";
    try {
      const response = await axios.get(url);
      if (
        response.data.status === 200 ||
        response.data.status === 201 ||
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        const sortedData = response.data.response.sort((a, b) => {
          // Convert dates to Date objects and compare them
          const dateA = a.id;
          const dateB = b.id;
          return dateB - dateA; // Ascending order
        });
        console.log("response--->", response);
        console.log("check response-->", response.data.response?.reverse());
        dispatch(setGetAllClientData(sortedData?.reverse()));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);
    }
  };

  // const handleClickEdit = (record) => {
  //   dispatch(setEditClientData(record));
  //   navigate(`editclient?clientId=${record.id}`, { replace: true });
  // }

  const PREFIX = "BMW";
  const columns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Client ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (text ? `${PREFIX}${text}` : "-"),
    },
    {
      title: "Email ID",
      dataIndex: "email_id",
      key: "email_id",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Employee Count",
      dataIndex: "employee_count",
      key: "employee_count",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (text) => (text ? formatDate(text) : "-"),
    },
    {
      title: "Subscription",
      dataIndex: "subscription_plan",
      key: "subscription_plan",
      render: (text) => (text ? text : "-"),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: "12%",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <div className="table_action_icons">
    //           {showTableHover === index && showTableIcons && (
    //             <>
    //               <EditOutlined
    //                 className="actionicon_style"
    //                 onClick={(e) => {e.stopPropagation(); handleClickEdit(record)}}
    //               />
    //               <DeleteOutlined
    //                 className="actionicon_style"
    //                 onClick={(e) => {
    //                   e.stopPropagation();
    //                   handleDelete(record, index);
    //                 }}
    //               />
    //             </>
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewClientData, setViewClientData] = useState(false);

  const navigate = useNavigate();

  const handleClicknavi = () => {
    navigate("/client/addclient", { replace: true });
  };

  return (
    <>
      <StyledClientWrapper>
        {viewClientData ? (
          <>
            <Details
              setViewClientData={setViewClientData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          </>
        ) : (
          <>
            <div>
              <Title level={3}>Client </Title>
            </div>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={16}>
                <div style={report.no}>
                  <div style={report.totalno}>
                    <div>
                      <Title className="client_totle_count" level={3}>
                        Total: {getAllClientData?.length}{" "}
                      </Title>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={4} lg={4}>
                <div className="">
                  <div style={report.filterstyle}>
                    <img src={filter} alt="filter" style={report.imagestyle} />
                    <input
                      type="text"
                      placeholder="Company name"
                      style={report.inputstyle}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={24} md={4} lg={4}>
                <div className="" style={report.addclient}>
                  <Button
                    onClick={handleClicknavi}
                    style={report.meadd}
                    type="primary"
                  >
                    Add Client{" "}
                  </Button>
                </div>
              </Col>
            </Row>

            {/* <div style={report.no}>
              <div style={report.totalno}>
                <div>
                  <Title className="client_totle_count" level={3}>
                    Total no: {getAllClientData?.length}{" "}
                  </Title>
                </div>
                <div className="company_name" style={report.inputbutton}>
                  <div className="filter-container">
                    <div style={report.filterstyle}>
                      <img
                        src={filter}
                        alt="filter"
                        style={report.imagestyle}
                      />
                      <input
                        type="text"
                        placeholder="Company name"
                        style={report.inputstyle}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div
                    className="add-client-container"
                    style={report.addclient}
                  >
                    <Button
                      style={report.meadd}
                      onClick={handleClicknavi}
                      type="primary"
                    >
                      Add Client{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}

            <div style={report.table}>
              {loading ? (
                <div style={{ marginLeft: "500px" }}>
                  <Spin />
                </div>
              ) : (
                <StyledTable
                  dataSource={getFilteredClient}
                  columns={columns}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "" : "non-white"
                  }
                  rowKey="checkin_id"
                  id="Table"
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onRow={(record, rowIndex) => ({
                    onClick: () => {
                      setSelectedRow(record);
                      setViewClientData(true);
                    },
                    onMouseEnter: () => setShowTableHover(rowIndex),
                    onMouseLeave: () => setShowTableHover(null),
                  })}
                />
              )}
            </div>
          </>
        )}
      </StyledClientWrapper>
    </>
  );
}

export default Client;
