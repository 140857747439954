import Col from "react-bootstrap/Col";
import { Form, Row } from "antd";
import UIElement from "../../UIElement";
import Styled from "styled-components";
import { forwardRef, useEffect, useImperativeHandle } from "react";

export const FormWrap = Styled.div`
.btn{
    border: none;
    padding: 10px 30px;
    width: 100%;
}
.center{  
    display: flex;
    justify-content: center;
    align-items: middle;
}
.end{
    display: flex;
    justify-content: end;
}
.start{
    display: flex;
    justify-content: start;
}
`;

const GridBuilder = forwardRef((props, ref) => {
  const [form] = Form.useForm();

  const {
    formStructureJson,
    handleChange,
    formSubmit,
    loading,
    handleClick,
    handleForgotPassword,
    domainStatus,
    urlLoader,
    initialValues = {},
    handleSelectChange,
    handleBack,
    isModalOpen = false,
    uploadFile,
  } = props;

  const colStyle = {};

  // Expose methods using `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit(); // Triggers form submission
    },
    validate: async () => {
      try {
        const values = await form.validateFields(); // Validates all fields
        return { valid: true, values };
      } catch (error) {
        return { valid: false, error };
      }
    },
  }));

  // Set form initial values on mount or when `initialValues` change
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues, isModalOpen]);

  return (
    <Form
      form={form}
      onFinish={(value) => formSubmit(value)}
      className="email_refer help_form"
      initialValues={initialValues}
      ref={ref}
    >
      <h6>{formStructureJson?.title}</h6>
      {formStructureJson.pages[0]?.fields.map((row, rowIndex) => (
        <Row gutter={12} key={rowIndex}>
          {row?.map((field, colIndex) => {
            return (
              <>
                {field?.length > 1 ? (
                  <Col
                    sm={row?.length === 2 ? 6 : row?.length === 3 ? 4 : 3}
                    className={row?.length === 1 && "action_form_button_style"}
                  >
                    <Row>
                      {field?.map((data) => (
                        <Col
                          key={data.id}
                          style={colStyle}
                          sm={
                            formStructureJson.pages[0]?.colSpan[rowIndex][
                              colIndex
                            ]
                          }
                        >
                          <FormWrap>
                            <UIElement
                              label={data?.label}
                              type={data?.type}
                              options={data?.options}
                              name={data?.name}
                              position={field?.position}
                              background={field?.background}
                              textColor={field?.textColor}
                              disabled={field?.disabled}
                              row={field?.row}
                              Placeholder={field?.Placeholder}
                              picker={field?.picker}
                              maxLength={field?.maxLength}
                              required={field?.required}
                              value={field?.value}
                              CountNumber={field?.CountNumber}
                              content={field?.content}
                              handleChange={handleChange}
                              handleSelectChange={handleSelectChange}
                              readOnly={field?.readOnly}
                              event={field?.event}
                              loading={loading}
                              handleClick={() => handleClick()}
                              handleBack={() => handleBack()}
                              onInput={field.onInput}
                              className={data?.position}
                              validationRules={data?.validationRules}
                              handleForgotPassword={handleForgotPassword}
                              onKeyDown={data?.onKeyDown}
                              suffixUrl={data?.suffixUrl}
                              domainStatus={domainStatus}
                              urlLoader={urlLoader}
                              link={data?.link}
                              form={form}
                              buttons={field?.buttons}
                              mode={field?.mode}
                              uploadFile={uploadFile}
                            />
                          </FormWrap>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ) : (
                  <Col
                    key={field.id}
                    style={colStyle}
                    sm={formStructureJson.pages[0]?.colSpan[rowIndex]}
                  >
                    <FormWrap>
                      <UIElement
                        label={field?.label}
                        type={field?.type}
                        options={field?.options}
                        name={field?.name}
                        position={field?.position}
                        background={field?.background}
                        textColor={field?.textColor}
                        disabled={field?.disabled}
                        row={field?.row}
                        Placeholder={field?.Placeholder}
                        picker={field?.picker}
                        maxLength={field?.maxLength}
                        required={field?.required}
                        value={field?.value}
                        content={field?.content}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        readOnly={field?.readOnly}
                        event={field?.event}
                        loading={loading}
                        handleClick={() => handleClick()}
                        handleBack={() => handleBack()}
                        className={field?.position}
                        validationRules={field?.validationRules}
                        handleForgotPassword={handleForgotPassword}
                        onKeyDown={field?.onKeyDown}
                        suffixUrl={field?.suffixUrl}
                        domainStatus={domainStatus}
                        urlLoader={urlLoader}
                        link={field?.link}
                        form={form}
                        mode={field?.mode}
                        buttons={field?.buttons}
                        onInput={field.onInput}
                        uploadFile={uploadFile}
                      />
                    </FormWrap>
                  </Col>
                )}
              </>
            );
          })}
        </Row>
      ))}
    </Form>
  );
});

export default GridBuilder;
