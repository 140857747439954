import { Route, Routes, useNavigate } from "react-router";
import ProtectedRouteAuth from "./ProtectedRoute";
import { routesData } from "./RoutesData";
import { useEffect } from "react";

const { unProtectedRoutes, protectedRoutes } = routesData;
const UIRoutes = ({ isLoggedIn }) => {
  // const isLoggedIn = true;
  const navigate = useNavigate();
  // const accessToken = useSelector(getAccessToken)
  // const isLoggedIn = useSelector((state) => state.authTracking.isLoggedIn);
  // const dispatch = useDispatch()

  // const loginStatus = accessToken ? checkLoginStatus(accessToken) : "NOT_LOGGED_IN"

  // const isLoggedIn = loginStatus === "LOGGED_IN"

  // TODO: Implement session expiry handling
  // const isSessionExpired = loginStatus === "SESSION_EXPIRED"

  const filterRouteWithAccess = (route) => {
    // const menuData = menuList.find((menu) => menu.key === route.key)
    // return menuData?.access || false
    return true;
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  // const { token } = useLocation().state || { token: "" }

  // useEffect(() => {
  //   if (token !== "") {
  //     dispatch(setAccessToken(token))
  //   }
  // }, [token, dispatch])

  return (
    <Routes>
      <Route element={<ProtectedRouteAuth isLoggedIn={isLoggedIn} />}>
        {protectedRoutes.filter(filterRouteWithAccess).map((route, index) => (
          <Route key={index} path={route.path} element={route.component}>
            {route.subRoutes &&
              route.subRoutes.map((subRoute, subIndex) => (
                <Route
                  key={subIndex}
                  path={subRoute.path}
                  element={subRoute.component}
                />
              ))}
          </Route>
        ))}

        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Route>
    </Routes>
  );
};

export default UIRoutes;
