import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import GridBuilder from "../../UIBuilderEngine/GridBuilder";
import { ButtonFilled } from "../../reusableComponent/UIButtons";
import { Form, message, Spin, Switch, Table, Checkbox } from "antd";
import UITable from "../../reusableComponent/UITable/UITable";
import { RightOutlined } from "@ant-design/icons";
import StyledClientWrapper from "./style";
import StepsProgress from "../../reusableComponent/StepProgress/StepsProgress";
import COLORS from "../../Common/Colors";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setGetAllClientData } from "../../Redux/systemSlice";
import { useDispatch, useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import { isEmpty } from "lodash";
import { Mode } from "@mui/icons-material";

const AddNewClient = ({ selectedRow, setSelectedRow }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [emailList, setEmailList] = useState([]);

  const CheckboxOption = ["HR", "Pay"];

  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );

  const EMAIL_REGEX = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const getEmailList = async () => {
    const response = await axios.get(
      "https://nz9pn78wqi.execute-api.ap-south-1.amazonaws.com/Prod/add-users/email-list"
    );

    setEmailList(response?.data?.response?.response);
  };

  const validateEmployeeId = (rule, value) => {
    if (value && emailList?.includes(value)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  const dataSource = [
    {
      key: 1,
      clientName: "Dashboard - Mine",
      name: "dashboard",
      access: selectedRow?.dashboard,
      planType: "Basic",
    },
    {
      key: 2,
      clientName: "Check in and check out through Biometrics",
      name: "checkinout_biometric",
      access: selectedRow?.checkinout_biometric,
      planType: "Basic",
    },
    {
      key: 3,
      clientName: "Leave application through portal",
      name: "leaveapplication_portal",
      access: selectedRow?.leaveapplication_portal,
      planType: "Basic",
    },
    {
      key: 4,
      clientName: "Calendar view - Mine",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 5,
      clientName: "Calendar view - Team",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 6,
      clientName: "Calendar view - All",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 7,
      clientName: "Standard Reporting (Daily Attendance & Leave Report)",
      name: "standard_reporting",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 8,
      clientName: "Exception Reports - Late check-in ",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 9,
      clientName: "Exception Reports - Early Check out",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 10,
      clientName: "Exception Reports - Absent Report",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 11,
      clientName: "Support",
      name: "support",
      access: selectedRow?.support,
      planType: "Basic",
    },
    {
      key: 12,
      clientName: "HR Resources - Policies",
      name: "hr_resources",
      access: selectedRow?.hr_resources,
      planType: "Lite",
    },
    {
      key: 13,
      clientName: "HR Resources - People",
      name: "hr_resources",
      access: selectedRow?.hr_resources,
      planType: "Lite",
    },
    {
      key: 14,
      clientName: "Manage",
      name: "manage",
      access: selectedRow?.manage,
      planType: "Lite",
    },
    {
      key: 15,
      clientName: "Shift Management",
      name: "shift_management",
      access: selectedRow?.shift_management,
      planType: "Lite",
    },

    {
      key: 16,
      clientName: "Document Management",
      name: "document_management",

      access: selectedRow?.document_management,
      planType: "Lite",
    },
    {
      key: 17,
      clientName: "Dashboard - My Team",
      name: "my_teams",

      access: selectedRow?.my_teams,
      planType: "Lite",
    },
    {
      key: 18,
      clientName: "Monthly Attendance Reports",
      name: "monthly_attandance_report",

      access: selectedRow?.monthly_attandance_report,
      planType: "Lite",
    },
    {
      key: 19,
      clientName: "Asset Management",
      name: "asset_management",

      access: selectedRow?.asset_management,
      planType: "Lite",
    },
    {
      key: 20,
      clientName: "Mobile App",
      name: "mobile_app",

      access: selectedRow?.mobile_app,
      planType: "Lite",
    },
    {
      key: 21,
      clientName: "Recruitment Management",
      name: "recruitment_manaement",

      access: selectedRow?.recruitment_manaement,
      planType: "Lite",
    },
    {
      key: 22,
      clientName: "Automated Onboarding",
      name: "automated_onboarding",

      access: selectedRow?.automated_onboarding,
      planType: "Lite",
    },
    {
      key: 23,
      clientName: "Appraisal Management",
      name: "apprisal_management",

      access: selectedRow?.apprisal_management,
      planType: "Lite",
    },
    {
      key: 24,
      clientName: "Dashboard - HR",
      name: "dashboard_hr",
      access: selectedRow?.dashboard_hr,
      planType: "Pro",
    },
    {
      key: 25,
      clientName: "Performance Management",
      name: "performance_management",

      access: selectedRow?.performance_management,
      planType: "Pro",
    },
    {
      key: 26,
      clientName: "Travel/ Expense Approvals Management",
      name: "travel_expenses",

      access: selectedRow?.travel_expenses,
      planType: "Pro",
    },

    {
      key: 27,
      clientName: "Expense Reimbursement",
      name: "expenses_reimbursment",

      access: selectedRow?.expenses_reimbursment,
      planType: "Pro",
    },
    {
      key: 28,
      clientName: "Disciplinary Management",
      name: "disciplinary_management",

      access: selectedRow?.disciplinary_management,
      planType: "Pro",
    },
    {
      key: 29,
      clientName: "Incident Reporting",
      name: "incident_reporting",
      access: selectedRow?.incident_reporting,
      planType: "Pro",
    },
    {
      key: 30,
      clientName: "Simba AI",
      name: "simba_ai",
      access: selectedRow?.simba_ai,
      planType: "Pro",
    },
    {
      key: 31,
      clientName: "Org Chart",
      name: "org_chart",
      access: selectedRow?.org_chart,
      planType: "Pro",
    },
    {
      key: 32,
      clientName: "Activity Log",
      name: "activity_log",
      access: selectedRow?.activity_log,
      planType: "Pro",
    },
    {
      key: 33,
      clientName: "Report Preferences",
      name: "report_preference",
      access: selectedRow?.report_preference,
      planType: "Pro",
    },
    {
      key: 34,
      clientName: "Integration with existing Payroll system",
      name: "integrateex_payroll",
      access: selectedRow?.integrateex_payroll,
      planType: "Pro",
    },
    {
      key: 35,
      clientName: "Payroll",
      name: "payroll",
      access: selectedRow?.payroll,
      planType: "Pro",
    },
  ];

  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ispermission, setIsPermission] = useState(false);
  const [clientPermissionData, setClientPermissionData] = useState(dataSource);
  const [menuList, setMenuList] = useState([]);
  const tabledata = [
    {
      id: 1,
      featurename: "Dashboard(Mine, My Team, HR)",
      // pricing: "-",
      subscription: "Basic | Lite | Pro",
    },
    {
      id: 2,
      featurename: "Check in and Check out through Biometrics",
      // pricing: "-",
      subscription: "Lite | Pro",
    },
    {
      id: 3,
      featurename: "Leave application through portal",
      // pricing: "-",
      subscription: "Lite",
    },
  ];

  const columns = [
    {
      title: "Feature name",
      dataIndex: "featurename",
      key: "featurename",
      width: "25%",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    //   width: "25%",
    // },
    // {
    //   title: "Available in Subscription Model",
    //   dataIndex: "subscription",
    //   key: "subscription",
    //   width: "25%",
    // },
    {
      title: "Access",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: () => {
        return <Switch size="small" />;
      },
    },
  ];

  const handleSubmitForm = async (e) => {
    try {
      await form.validateFields();
      // setStep(1);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClickCancel = () => {
    navigate("/client");
  };

  const SubcriptionPlanData = [
    { value: "basic", label: "Basic" },
    { value: "lite", label: "Lite" },
    { value: "pro", label: "Pro" },
  ];

  //const [digitCount, setDigitCount] = useState(4);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        console.log("[fetchCountries] Inside -->");
        const response = await axios.get("https://restcountries.com/v3.1/all");

        console.log("[fetchCountries] response -->", response);
        const countryOptions = response.data.map((country) => ({
          label: country.name.common, // Display name
          value: country.name.common, // Country code as value
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const SystemList = ["HR", "Pay"];

  const addNewClientForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [],
        fields: [
          [
            {
              id: 1,
              label: "Company Name:",
              type: "text",
              name: "company_name",
              Placeholder: "",
              maxLength: 50,
              onKeyDown: /^[a-zA-Z\s]+$/,
              validationRules: [
                { required: true, message: "Company name is required" },
                {
                  min: 0,
                  max: 50,
                  // message: "Company name must be within 50 alphabetic characters",
                },
                // {
                //   pattern: /^[a-zA-Z\s]*$/,
                //   // message: "Company name cannot contain numbers or special characters",
                // },
              ],
            },
            {
              id: 2,
              label: "Client Name:",
              type: "text",
              name: "client_name",
              Placeholder: "",
              maxLength: 50,
              onKeyDown: /^[a-zA-Z\s]+$/,
              validationRules: [
                { required: true, message: "Client name is required" },
                {
                  min: 0,
                  max: 50,
                  // message: "Client name must be 3-50 alphabetic characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  // message: "Client name cannot contain numbers or special characters",
                },
              ],
              onInput: (e) => {
                e.target.value = e.target.value.replace(/[0-9]/g, "");
              },
            },
          ],
          [
            {
              id: 3,
              label: "Location:",
              type: "select",
              name: "location",
              //options: countries,
              options: [
                { label: "Kenya", value: "Kenya" },
                { label: "India", value: "India" },
              ],
              Placeholder: "Select Location",
              validationRules: [
                { required: true, message: "Location is required" },
              ],
            },
            {
              id: 4,
              label: "Employee Count / Company Size:",
              type: "text",
              name: "employee_count",
              maxLength: 4,
              Placeholder: "",
              onKeyDown: /^[0-9]$/,
              validationRules: [
                { required: true, message: "Employee Count is required" },
              ],
              // CountNumber: digitCount,
            },
          ],
          [
            {
              id: 5,
              label: "Contact Person:",
              type: "text",
              name: "contact_person",
              onKeyDown: /^[a-zA-Z\s]+$/,
              Placeholder: "",
              maxLength: 30,
              validationRules: [
                {
                  required: true,
                  message: "Contact Person is required",
                },
                {
                  min: 0,
                  max: 30,
                  // message: "Contact Person must be 3-50 alphabetic characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  // message: "Contact Person cannot contain numbers or special characters",
                },
              ],
            },
            {
              id: 6,
              label: "Contact Number:",
              type: "phone",
              name: "contact_number",
              maxLength: 20,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Contact Number is required" },
                {
                  min: 0,
                  max: 20,
                },
                {
                  pattern: /^\+?[0-9]{10,20}$/,
                  message: " ",
                },
              ],
              validateTrigger: "onBlur",
            },
          ],
          [
            {
              id: 7,
              label: "Email ID:",
              type: "email",
              name: "email_id",
              Placeholder: "",
              validationRules: [
                {
                  required: true,
                  pattern: EMAIL_REGEX,
                  message: "Email ID is required",
                },
                { validator: validateEmployeeId },
              ],
            },
            {
              id: 8,
              label: "Amount ($):",
              type: "text",
              name: "amount",
              maxLength: 10,
              onKeyDown: /^[0-9]$/,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Amount is required" },
                {
                  min: 0,
                  max: 10,
                },
                {
                  pattern: /^[0-9]{1,10}$/,
                  message:
                    "Amount must be a number less than or equal to 10 characters",
                },
              ],
            },
            // {
            //   id: 8,
            //   label: "Password:",
            //   type: "password",
            //   name: "password",
            //   Placeholder: "",
            // }
          ],
          [
            {
              id: 9,
              label: "Subscription Plan:",
              type: "select",
              name: "subscription_plan",
              options: SubcriptionPlanData,
              Placeholder: "Select Subscription Plan ",
              validationRules: [
                { required: true, message: "Subscription Plan is required" },
              ],
            },
            {
              id: 10,
              label: "Subscription Frequency:",
              type: "select",
              name: "supscription_frequency",

              options: [
                { label: "Bi-annually", value: "biannually" },
                { label: "Quaterly", value: "quaterly" },
                { label: "Monthly", value: "monthly" },
                { label: "Yearly", value: "yearly" },
              ],
              Placeholder: "Select Subscription Frequency",
              validationRules: [
                {
                  required: true,
                  message: "Subscription Frequency is required",
                },
              ],
            },
          ],
          [
            {
              id: 11,
              label: "Discount:",
              type: "text",
              name: "discount",
              Placeholder: " ",
              onKeyDown: /^[0-9]$/, // Allow only digits
            },
            {
              id: 12,
              label: "Subscriber Count:",
              type: "text",
              name: "subscriber_count",
              Placeholder: "",

              onKeyDown: /^[0-9]$/,
            },
          ],
          [
            {
              id: 13,
              label: "Status:",
              type: "select",
              name: "status",
              options: [
                { label: "New", value: "new" },
                { label: "Lead", value: "lead" },
                { label: "Follow-up", value: "followup" },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ],
              Placeholder: "Select Status",
              validationRules: [
                { required: true, message: "Status is required" },
              ],
            },
            {
              id: 20,
              label: "Password:",
              type: "password",
              name: "password",
              validationRules: [
                {
                  required: true,
                  message: "Password is required.",
                },
                {
                  validator: (_, value) => {
                    if (!value || value.trim()?.length === 0) {
                      return Promise.reject("");
                    } else if (value?.length < 8 || value?.length > 15) {
                      return Promise.reject(
                        "Password should be min 8 to max 15 characters."
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ],
              Placeholder: "Password",
              maxLength: 15,
              // onKeyDown: /^[a-zA-Z\s]+$/,
            },
          ],
          [
            {
              id: 13,
              label: "System:",
              type: "checkboxgroup",
              name: "system",
              options: CheckboxOption,
              Placeholder: "System Status",
              validationRules: [
                { required: true, message: "System is required" },
              ],
            },
          ],
          // [
          //   {
          //     label: "HR",
          //     type: "checkbox",
          //     id: 21,
          //   },
          // ],
          // [
          //   {
          //     id: 15,
          //     // label: "Cancel",
          //     type: "radiogroup",
          //     position: "end",
          //     textColor: "#2D53DA",
          //     radios: [
          //       {
          //         label: "Cancel",
          //         disabled: false,
          //         checked: true,
          //         // loading: false,
          //         // btnStyle: ActionButtonStyle.cancelButton,
          //         onClick: () => {
          //           handleClickCancel();
          //         },
          //       },
          //       {
          //         label: "Next",
          //         disabled: false,
          //         checked: true,
          //         // loading: false,
          //         // type: "submit",
          //         // btnStyle: ActionButtonStyle.NextButton,
          //         onClick: () => {
          //           // handleSubmitForm()
          //         },
          //       },
          //     ],
          //   },
          // ],
        ],
      },
    ],
  };

  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    client_name: "",
    email_id: "",
    // subscription_plan: "basic",
  });
  const stepdata = [
    // { id: 1, title: "Basic" },
    // { id: 2, title: "Features" },
  ];

  const getClientId = (name) => {
    const uniqueId = Math.random().toString(36).substr(2, 5);
    let clientId =
      name[0].toUpperCase() + name[1].toUpperCase() + uniqueId.toUpperCase();
    return clientId;
  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  console.log("data-->", data);

  const handleSelectChange = (value, name) => {
    console.log("Selected value:", value);
    console.log("Field name:", name);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getMenuList = async () => {
    try {
      const API =
        "https://peo32t8k2i.execute-api.ap-south-1.amazonaws.com/dev/user/menulist";
      const response = await axios.get(API);
      setMenuList(response?.data?.response?.menulistjson);
    } catch (error) {}
  };
  useEffect(() => {
    getMenuList();
    getEmailList();
  }, []);

  const handlesubmit = async (clientId) => {
    try {
      const userauthregisterurl =
        "https://peo32t8k2i.execute-api.ap-south-1.amazonaws.com/dev/user/register";

      const updatedValues = CheckboxOption.reduce((acc, option) => {
        acc[option] = data?.system?.includes(option) || false;
        return acc;
      }, {});

      const payload = {
        companyName: data?.company_name,
        country: "",
        domain: "",
        email: data?.email_id,
        employeeCount: data?.employee_count,
        firstName: data?.client_name,
        interval: "",
        lastName: "",
        monthlyCost: 0,
        password: data?.password,
        paymentType: "free",
        productList: "",
        totalCost: 0,
        type: "company",
        subscription_plan: data?.subscription_plan,
        phoneNumber: data?.contact_number,
        usermenuList: menuList,
        clientId: clientId,
        amount: data?.amount,
        discount: data?.discount,
        subscriber_count: data?.subscriber_count,
        supscription_frequency: data?.supscription_frequency,
        system: updatedValues,
      };

      const response = await axios.post(userauthregisterurl, payload);
      if (
        response?.data?.status === 201 ||
        response?.data?.status === 200 ||
        response?.data?.statusCode === 201 ||
        response?.data?.statusCode === 200
      ) {
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      message.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleSubmitAddForm = async (e) => {
    const templateParams = {
      client_name: data.client_name,
      email_id: data.email_id,
      password: data?.password,
    };
    try {
      const response = await emailjs.send(
        "service_3tvmhjr", // Replace with your EmailJS service ID
        "template_daebw3r", // Replace with your EmailJS template ID
        templateParams,
        "y7S-zF4Gp3oh0XO4p" // Replace with your EmailJS public key
      );
      if (response?.status === 200) {
        await new Promise((resolve) => {
          message.success("Email sent successfully to the user!");
          resolve();
        });
        // alert("Email sent successfully to the user!");
        console.log("Success:", response.status, response.text);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handlePostClientData = async (e) => {
    const name =
      data?.type === "personal" ? data.client_name : data?.company_name;
    const clientId = getClientId(name);
    setLoading(true);
    handlesubmit(clientId);
    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/addData";
    const obj = {
      client_name: data?.client_name,
      system_name: data?.Support,
      basic_plan: !!data?.basic_plan,
      advanced_plan: !!data?.advanced_plan,
      enterprise_plan: !!data?.enterprise_plan,
      status: data?.status,
      updated_date: new Date().toLocaleDateString("en-CA"),
      created_date: new Date().toLocaleDateString("en-CA"),
      updated_by: "",
      created_by: "",
      location: data?.location,
      email_id: data?.email_id,
      employee_count: data?.employee_count,
      subscription_plan: data?.subscription_plan,
      contact_person: data?.contact_person,
      contact_number: data?.contact_number,
      company_name: data?.company_name,
      password: data?.password,
      usermenulist: menuList,
      clientid: clientId,
      amount: data?.amount,
      discount: data?.discount,
      subscriber_count: data?.subscriber_count,
      supscription_frequency: data?.supscription_frequency,
      system: data?.system,
      // dashboard: !!data?.dashboard,
      // checkinout_biometric: !!data?.checkinout_biometric,
      // leaveapplication_portal: !!data?.leaveapplication_portal,
      // calender_view: !!data?.calender_view,
      // exception_report: !!data?.exception_report,
      // support: !!data?.support,
      // standard_reporting: !!data?.standard_reporting,

      // hr_resources: !!data?.hr_resources,
      // manage: !!data?.manage,
      // shift_management: !!data?.shift_management,
      // document_management: !!data?.document_management,
      // my_teams: !!data?.my_teams,
      // monthly_attandance_report: !!data?.monthly_attandance_report,
      // asset_management: !!data?.asset_management,
      // mobile_app: !!data?.mobile_app,
      // recruitment_manaement: !!data?.recruitment_manaement,
      // automated_onboarding: !!data?.automated_onboarding,
      // apprisal_management: !!data?.apprisal_management,

      // performance_management: !!data?.performance_management,
      // travel_expenses: !!data?.travel_expenses,
      // expenses_reimbursment: !!data?.expenses_reimbursment,
      // disciplinary_management: !!data?.disciplinary_management,
      // incident_reporting: !!data?.incident_reporting,
      // simba_ai: !!data?.simba_ai,
      // org_chart: !!data?.org_chart,
      // activity_log: !!data?.activity_log,
      // report_preference: !!data?.report_preference,
      // integrateex_payroll: !!data?.integrateex_payroll,
      // payroll: !!data?.payroll,
    };
    try {
      const response = await axios.post(url, obj);
      if (
        response?.data?.status === 201 ||
        response?.data?.status === 200 ||
        response?.data?.statusCode === 201 ||
        response?.data?.statusCode === 200
      ) {
        setLoading(false);
        const newData = response.data.response;

        dispatch(setGetAllClientData([newData, ...getAllClientData]));
        handleSubmitAddForm(e);
        navigate("/client");

        message.success("Client added successfully!");
      } else {
        setLoading(false);
        message.error("Failed to add client. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);
      message.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handledCheckboxChange = (checked, menu, submenu) => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList?.map((item) => {
        if (item?.id === menu) {
          if (submenu?.length > 0) {
            const updatedSubmenu = item?.subMenu?.map((subItem) => {
              if (subItem?.key === submenu) {
                return {
                  ...subItem,
                  permission: {
                    create: checked,
                    view: checked,
                    delete: checked,
                    edit: checked,
                  },
                  access: checked,
                };
              }
              return subItem;
            });
            const allSubMenuAccessFalse =
              updatedSubmenu?.length > 0 &&
              updatedSubmenu?.every((subItem) => subItem?.access === false);
            return {
              ...item,
              access: allSubMenuAccessFalse ? false : true,
              subMenu: updatedSubmenu,
            };
          } else {
            return {
              ...item,
              access: checked,
              permission: {
                create: checked,
                view: checked,
                delete: checked,
                edit: checked,
              },
            };
          }
        }

        return item;
      });
      return updatedList;
    });
  };

  useEffect(() => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList?.map((item) => {
        // Define allowed plan types based on subscription_plan
        const allowedPlans = [];
        if (data?.subscription_plan?.toLowerCase() === "basic") {
          allowedPlans.push("basic");
          // item?.key !== "allapprovals" ? item?.planType === "Basic" : item
        } else if (data?.subscription_plan?.toLowerCase() === "lite") {
          allowedPlans.push("basic", "lite");
        } else if (data?.subscription_plan?.toLowerCase() === "pro") {
          allowedPlans.push("basic", "lite", "pro");
        }

        // Check if the item's planType matches any of the allowed plans
        const isAllowedPlan =
          item.key !== "allapprovals"
            ? allowedPlans?.includes((item?.planType || "").toLowerCase())
            : item;
        if (item?.subMenu?.length > 0) {
          // Update subMenu items based on access
          const updatedSubmenu = item?.subMenu.map((subItem) => ({
            ...subItem,
            access:
              data?.subscription_plan === "basic" &&
              subItem?.key === "travelexpense"
                ? false
                : isAllowedPlan,
            permission: {
              create:
                data?.subscription_plan === "basic" &&
                subItem?.key === "travelexpense"
                  ? false
                  : isAllowedPlan,
              edit:
                data?.subscription_plan === "basic" &&
                subItem?.key === "travelexpense"
                  ? false
                  : isAllowedPlan,
              delete:
                data?.subscription_plan === "basic" &&
                subItem?.key === "travelexpense"
                  ? false
                  : isAllowedPlan,
              view:
                data?.subscription_plan === "basic" &&
                subItem?.key === "travelexpense"
                  ? false
                  : isAllowedPlan,
            },
          }));
          return {
            ...item,
            access: isAllowedPlan,
            subMenu: updatedSubmenu,
          };
        }

        // Update the parent item directly if no subMenu
        return {
          ...item,
          access: isAllowedPlan,
          permission: {
            create: isAllowedPlan,
            edit: isAllowedPlan,
            delete: isAllowedPlan,
            view: isAllowedPlan,
          },
        };
      });

      return updatedList;
    });
  }, [data?.subscription_plan]);

  const groupedData = {
    Basic: menuList?.filter((item) =>
      item?.key !== "allapprovals" ? item?.planType === "Basic" : item
    ),
    Lite: menuList?.filter((item) =>
      item?.key !== "allapprovals" ? item?.planType === "Lite" : item
    ),
    Pro: menuList?.filter((item) => item?.planType === "Pro"),
  };

  const renderPlanBox = (planType, menu) => (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
        backgroundColor: "#f9f9f9",
        flex: "1",
      }}
    >
      <h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        {planType} Plan
      </h3>
      {menu.map((item) => (
        <div key={item.key} style={{ marginBottom: "10px" }}>
          {item?.subMenu?.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{item?.menuName}</span>
              <Checkbox
                name={item.name}
                checked={item?.access}
                style={{ width: "30px" }}
                onChange={(e) =>
                  handledCheckboxChange(e.target.checked, item.id, "")
                }
              />
            </div>
          )}
          {item?.subMenu?.length > 0 && (
            <>
              {item?.subMenu
                ?.filter((item) => item?.planType === planType)
                .map((val) => (
                  <div key={item.key} style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{val?.menuName}</span>
                      <Checkbox
                        name={val.name}
                        style={{ width: "30px" }}
                        checked={val?.access}
                        onChange={(e) =>
                          handledCheckboxChange(
                            e.target.checked,
                            item.id,
                            val.key
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
  const navigate = useNavigate();

  return (
    <StyledClientWrapper>
      {ispermission ? (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => setIsPermission(false)}
          ></label>{" "}
          <b className="pb-0 pt-1">Select Permission</b>
          <UITable data={tabledata} columns={columns} />
          <div className="text-right w-100 p-1">
            <ButtonFilled
              className="text-end logout-button"
              // type="submit"
              htmlType="submit"
              onClick={() => setIsPermission(true)}
            >
              Create
            </ButtonFilled>
          </div>
        </>
      ) : (
        <>
          <div>
            <div>
              <h5 className="view_client_goback">
                <p onClick={() => navigate("/client")}>Client</p>{" "}
                <RightOutlined className="right_out_icon" />{" "}
                <storng>Adding Client</storng>{" "}
              </h5>
            </div>
            <StepsProgress stepdata={stepdata} title="Add Role:" step={step} />
            {step === 0 && (
              <>
                <GridBuilder
                  formStructureJson={addNewClientForm}
                  formSubmit={(e) => handlePostClientData(e)}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  handleBack={handleClickCancel}
                  initialValues={data}
                  ref={formRef}
                />
              </>
            )}
            <br />
            <br />

            {step === 0 && (
              <>
                <div style={{ display: "flex", gap: "20px" }}>
                  {Object.entries(groupedData).map(([planType, data]) =>
                    renderPlanBox(planType, data)
                  )}
                </div>

                <div className="text-right w-100 form_button_section">
                  <ButtonFilled
                    className={loading ? "custom-style-btn" : "logout-button"}
                    type="primary"
                    // htmlType="submit"
                    disabled={loading}
                    onClick={(e) => {
                      // Call form submission
                      formRef.current?.submit();

                      // Call handleSubmitAddForm
                      // handleSubmitAddForm(e);
                    }}
                  >
                    {loading ? <Spin /> : "Create"}
                  </ButtonFilled>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </StyledClientWrapper>
  );
};

export default AddNewClient;
