import React, { useEffect, useState } from "react";
import report1 from "../client/detailstyle/report1";
import { RightOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Spin, Checkbox } from "antd";
import { Table } from "antd";
import { Switch } from "antd";
import "react-phone-number-input/style.css";
import StyledClientWrapper from "../style";
import styled from "styled-components";
import COLORS from "../../../Common/Colors";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditClientData,
  setGetAllClientData,
} from "../../../Redux/systemSlice";
import { useNavigate } from "react-router-dom";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

const DetailsData = ({ setViewClientData, selectedRow, setSelectedRow }) => {
  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );
  console.log("getclientdata-->", selectedRow);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectDeleteData, setSelectDeleteData] = useState();
  const [menuList, setMenuList] = useState([]);

  console.log("menuList1--->", menuList);

  useEffect(() => {
    setMenuList(selectedRow?.usermenulist);
  }, [selectedRow]);

  const handleClickEdit = (record) => {
    dispatch(setEditClientData(record));
    navigate(`editclient?clientId=${record.id}`, { replace: true });
  };

  const handleDelete = async (data, index) => {
    setSelectDeleteData(data);
    setDeleteModalOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/deleteData";
    try {
      const response = await axios.delete(`${url}/${selectDeleteData.id}`);
      if (
        response.data.status === 200 ||
        response.data.status === 201 ||
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        setLoading(false);
        const findIndex = getAllClientData.findIndex(
          (item) => item.id === selectDeleteData.id
        );
        if (findIndex !== -1) {
          const UpdatedClientData = [...getAllClientData];
          UpdatedClientData.splice(findIndex, 1);
          dispatch(setGetAllClientData(UpdatedClientData));
          setSelectedRow(null);
          setViewClientData(false);
        }
        setDeleteModalOpen(false);
        message.success("Deleted successfully!");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);

      message.error("Failed to delete. Please try again.");
    }
  };

  const columns = [
    {
      title: "Feature Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    // },
    // {
    //   title: "Available In",
    //   dataIndex: "availableIn",
    //   key: "availableIn",
    // },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (text, record) => (
        <Switch
          className="switch_button"
          defaultChecked
          name={record.clientName}
          size="small"
          checked={record.access}
        />
      ),
    },
  ];

  const onChange1 = (e) => {
    console.log(`checked=${e.target.checked}`);
  };

  const groupedData = {
    Basic: menuList?.filter((item) =>
      item?.key !== "allapprovals" ? item?.planType === "Basic" : item
    ),
    Lite: menuList?.filter((item) =>
      item?.key !== "allapprovals" ? item?.planType === "Lite" : item
    ),
    Pro: menuList?.filter((item) => item?.planType === "Pro"),
  };

  const handledCheckboxChange = (checked, menu, submenu) => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList?.map((item) => {
        if (item?.id === menu) {
          if (submenu?.length > 0) {
            const updatedSubmenu = item?.subMenu?.map((subItem) => {
              if (subItem?.key === submenu) {
                return {
                  ...subItem,
                  permission: {
                    create: checked,
                    view: checked,
                    delete: checked,
                    edit: checked,
                  },
                  access: checked,
                };
              }
              return subItem;
            });
            const allSubMenuAccessFalse =
              updatedSubmenu?.length > 0 &&
              updatedSubmenu?.every((subItem) => subItem?.access === false);
            return {
              ...item,
              access: allSubMenuAccessFalse ? false : true,
              subMenu: updatedSubmenu,
            };
          } else {
            return {
              ...item,
              access: checked,
              permission: {
                create: checked,
                view: checked,
                delete: checked,
                edit: checked,
              },
            };
          }
        }

        return item;
      });
      return updatedList;
    });
  };

  const renderPlanBox = (planType, menu) => (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
        backgroundColor: "#f9f9f9",
        flex: "1",
      }}
    >
      <h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        {planType} Plan
      </h3>
      {menu?.map((item) => (
        <div key={item.key} style={{ marginBottom: "10px" }}>
          {item?.subMenu?.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{item?.menuName}</span>
              <Checkbox
                name={item.name}
                checked={item?.access}
                style={{ width: "30px" }}
                onChange={(e) =>
                  handledCheckboxChange(e.target.checked, item.id, "")
                }
              />
            </div>
          )}
          {item?.subMenu?.length > 0 && (
            <>
              {item?.subMenu
                ?.filter((item) => item?.planType === planType)
                ?.map((val) => (
                  <div key={item.key} style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{val?.menuName}</span>
                      <Checkbox
                        name={val.name}
                        style={{ width: "30px" }}
                        checked={val?.access}
                        onChange={(e) =>
                          handledCheckboxChange(
                            e.target.checked,
                            item.id,
                            val.key
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <StyledClientWrapper>
        <div className="client_details_container">
          <div>
            <h5 style={report1.clientbtn} className="view_client_goback">
              <p onClick={() => setViewClientData(false)}>Client</p>{" "}
              <RightOutlined className="right_out_icon" />{" "}
              <storng>Details</storng>{" "}
            </h5>
          </div>
          <br></br>
          <Row gutter={12} className="client_details_wrapper">
            <Col xl={8}>
              <div className="client_details_data_wrap">
                <p>Client Name:</p>
                <p className="client_details_ans">
                  {selectedRow?.client_name?.charAt(0).toUpperCase() +
                    selectedRow?.client_name?.slice(1) || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Location: </p>
                <p className="client_details_ans">
                  {selectedRow?.location?.charAt(0).toUpperCase() +
                    selectedRow?.location?.slice(1) || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Contact Person:</p>
                <p className="client_details_ans">
                  {selectedRow?.contact_person?.charAt(0).toUpperCase() +
                    selectedRow?.contact_person?.slice(1) || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Email ID:</p>
                <p className="client_details_ans">
                  {selectedRow?.email_id || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Company Name:</p>
                <p className="client_details_ans">
                  {selectedRow?.company_name?.charAt(0).toUpperCase() +
                    selectedRow?.company_name?.slice(1) || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Subscriber Frequency:</p>
                <p className="client_details_ans">
                  {selectedRow?.supscription_frequency || "-"}
                </p>
              </div>
            </Col>

            <Col xl={8}>
              <div className="client_details_data_wrap">
                <p>Client Id:</p>
                <p className="client_details_ans">
                  {`BMW${selectedRow?.id}` || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Employee Count:</p>
                <p className="client_details_ans">
                  {selectedRow?.employee_count || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Contact No:</p>
                <p className="client_details_ans">
                  {selectedRow?.contact_number || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Subscriber Count:</p>
                <p className="client_details_ans">
                  {selectedRow?.subscriber_count || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Discount:</p>
                <p className="client_details_ans">
                  {selectedRow?.discount || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Amount:</p>
                <p className="client_details_ans">
                  {selectedRow?.amount ? `${selectedRow.amount}$` : "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>System:</p>
                <p className="client_details_ans">
                  {selectedRow?.system
                    ? `${selectedRow.system?.map((val) => val?.join(","))}$`
                    : "-"}
                </p>
              </div>

              {/* <div className="client_details_data_wrap">
                <p>Password</p>
                <p className="client_details_ans">
                  {selectedRow?.employee_count}
                </p>
              </div> */}
            </Col>

            <Col xl={8}>
              <div className="client_detail_indigators">
                {selectedRow?.status && (
                  <div className="client_status_type">
                    <p
                      className={
                        selectedRow?.status === "inactive" || "Inactive"
                          ? "inactive_button"
                          : "active_button"
                      }
                    >
                      {selectedRow?.status?.charAt(0).toUpperCase() +
                        selectedRow?.status?.slice(1)}
                    </p>
                  </div>
                )}
                <div className="client_subscription_type">
                  &nbsp;&nbsp;
                  <div className="circle_icon"></div>{" "}
                  <span>
                    {selectedRow?.subscription_plan?.charAt(0).toUpperCase() +
                      selectedRow?.subscription_plan?.slice(1)}
                  </span>
                </div>
                <EditOutlined onClick={() => handleClickEdit(selectedRow)} />
                <DeleteOutlined onClick={() => handleDelete(selectedRow)} />
              </div>
            </Col>
          </Row>
          <br></br>

          {/* <div style={report1.scrollable}>
            <StyledTable
              dataSource={dataSource}
              columns={columns}
              rowClassName={() => "custom-row"}
              pagination={false}
            />
          </div> */}
          <div style={{ display: "flex", gap: "20px" }}>
            {Object.entries(groupedData)?.map(([planType, data]) =>
              renderPlanBox(planType, data)
            )}
          </div>
        </div>

        <Modal
          open={deleteModalOpen}
          footer={false}
          onCancel={handleCloseDelete}
        >
          <StyledClientWrapper>
            <p>Are you sure you want to delete this client?</p>
            <div className="delete_modal_footer">
              <Button
                className="cancel_button_style"
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} className="logout-button">
                {loading ? <Spin /> : "Delete"}
              </Button>
            </div>
          </StyledClientWrapper>
        </Modal>
      </StyledClientWrapper>
    </>
  );
};

export default DetailsData;
