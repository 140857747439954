import "./App.css";
import React, { useEffect, useState } from "react";
import UIRoutes from "./Routes/Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Spin } from "antd";

const queryClient = new QueryClient();

function App() {
  const redirectToNewDomain = () => {
    window.location.href = "https://www.dev.website.tymeplushr.com/";
  };

  //for local use---->
  // const token =
  //   "eyJjbGllbnRJZCI6IldBSk1PMUIiLCJ1c2VySWQiOiJzdXBvcnRzeXN0ZW1AdHltcGx1c2hyLmNvbSIsInVzZXJOYW1lIjoiT3duZXIiLCJ1c2VyVHlwZSI6Ik93bmVyIiwic2lnbkluVG8iOnsidHltZXBsdXNMbXNTdGF0dXMiOmZhbHNlLCJ0eW1lcGx1c1BheVN0YXR1cyI6ZmFsc2UsInR5bWVwbHVzU3VwcG9ydCI6dHJ1ZSwidHltZXBsdXNIclN0YXR1cyI6ZmFsc2V9LCJpYXQiOjE3MzQ2ODU4OTEsImV4cCI6MTczNDcyOTA5MX0";

  const urlParams = new URLSearchParams(window.location.search);
  var token = urlParams.get("token");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log("working");

    const fetchData = async () => {
      console.log("token-->", token);
      try {
        if (token) {
          window.localStorage.removeItem("token");
          window.localStorage.setItem("token", token);
          sessionStorage.setItem(
            "tokenTimestamp",
            new Date().getTime().toString()
          );
          setIsLoggedIn(true);
        }
        let isToken = window.localStorage.getItem("token");
        console.log("isToken-->", isToken);
        if (!isToken && token === null) {
          redirectToNewDomain();
        } else {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the function here after its definition
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <UIRoutes isLoggedIn={isLoggedIn} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
